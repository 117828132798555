import React, { useState, useEffect } from 'react'
import { FaBars, FaTimes, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import Logo from '../assets/logo.png'
import { Link } from 'react-scroll'

const Header = () => {
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)
  const [bg, setBg] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 80) {
        setBg(true)
      } else {
        setBg(false)
      }
    })
  })

  return (
    <header
    className={`${
      bg && 'bg-black shadow-md py-2'
    } p-4 lg:px-0 z-10 transition-all duration-300 fixed w-full h-[50px] flex justify-between items-center px-4 bg-[#7D3F98] text-yellow-300`}
  >
      <div className='container mx-auto flex items-center justify-between  sm:text-7x1  font-bold text-[#ccd6f6]'>
        <Link
          onClick={handleClick}
          to='home'
          smooth={true}
          duration={500}
          className='flex items-center'
        >
          <img
            src={Logo}
            alt='Logo'
            style={{ width: '40px', cursor: 'pointer', borderRadius: '50%' }}
          />
          <span className='ml-2'>GoRecta</span>
        </Link>

        {/* menu */}
        <ul className='hidden md:flex'>
          <li>
            <Link to='home' smooth={true} duration={500}>
              HOME
            </Link>
          </li>
          <li>
            <Link to='prices' smooth={true} duration={500}>
              SERVICES
            </Link>
          </li>
          <li>
            <Link to='questions' smooth={true} duration={500}>
              QUESTIONS
            </Link>
          </li>
        </ul>

        {/* Hamburger */}
        <div onClick={handleClick} className='md:hidden z-10'>
          {!nav ? <FaBars /> : <FaTimes />}
        </div>
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'
        }
      >
        <li className='py-6 text-4xl'>
          <Link to='home' smooth={true} duration={500} onClick={handleClick}>
            HOME
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link to='prices' smooth={true} duration={500} onClick={handleClick}>
            SERVICES
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          <Link to='questions' smooth={true} duration={500} onClick={handleClick}>
            QUESTIONS
          </Link>
        </li>
      </ul>

      {/* Social icons */}
      <div className='hidden md:flex fixed flex-col top-[35%] left-0'>
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.linkedin.com/company/gorecta/'
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#d6249f]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.instagram.com/go_recta/'
            >
              Instagram <FaInstagram size={30} />{' '}
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[rgb(240,60,60)]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.youtube.com/@gorecta'
            >
              Youtube <FaYoutube size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='mailto:info@gorecta.com'
            >
              Email <HiOutlineMail size={30} />{' '}
            </a>
          </li>
        </ul>
      </div>

      {/* Social icons for mobile view */}
      <div className='md:hidden fixed bottom-0 left-0 w-full bg-[#333333] text-gray-300 p-2'>
        <ul className='flex justify-around'>
          <li>
            <a href='https://www.linkedin.com/company/gorecta/'>
              <FaLinkedin size={28} color='#2867B2' />
            </a>
          </li>
          <li>
            <a href='https://www.instagram.com/go_recta/'>
              <FaInstagram size={28} color='#E4405F' />
            </a>
          </li>
          <li>
            <a href='https://www.youtube.com/@gorecta'>
              <FaYoutube size={28} color='#FF0000' />
            </a>
          </li>
          <li>
            <a href='mailto:info@gorecta.com'>
              <HiOutlineMail size={28} color='#FFFFFF' />
            </a>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header
