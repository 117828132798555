import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-scroll';
import { HiArrowNarrowRight } from 'react-icons/hi';
// import { useTranslation } from "react-i18next";
import resume from '../assets/resume1.png';

const Home = () => {
  const [nav, setNav] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleClick = useCallback(() => setNav((prevNav) => !prevNav), []);
  const handleMouseEnter = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  // const { t } = useTranslation();

  return (
    <div
      name='home'
      className='w-full h-screen bg-[#00467d] flex flex-col justify-center items-center pt-20'
    >
      <div className='max-w-[1000px] mx-auto px-4 flex flex-col items-center h-full'>
        <div className='flex justify-center mt-6  mb-6'>
          <Link onClick={handleClick} to='questions' smooth={true} duration={500}>
            <button className='text-white group border-2 px-5 py-3 flex items-center justify-center hover:bg-purple-600 hover:border-pink-600'>
              Check Interview Questions
              <span className='group-hover:rotate-90 duration-300'>
                <HiArrowNarrowRight className='ml-3' />
              </span>
            </button>
          </Link>
        </div>
        <div className='w-full flex justify-center items-center'>
          {/* Container for the image and logo */}
          <div
            className='relative w-full max-w-[400px]'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* Image for free resume session events */}
            <Link onClick={handleClick} to='resume' smooth={true} duration={500}>
              <a href='/'>
                <img src={resume} alt='Resume Session' className='w-full h-auto' />
              </a>
            </Link>
          </div>
        </div>
      </div>
      <footer className='fixed w-full h-[40px] flex justify-center items-center bg-black text-white px-4'>
        {`© Copyright ${currentYear} All Rights Reserved`}
      </footer>
    </div>
  );
}

export default Home;
